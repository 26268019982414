
import {defineComponent} from 'vue';
import DetailLayout from '@/layouts/DetailLayout.vue';
import useUserDetail from '@/views/user/detail/userDetail';

export default defineComponent({
  name: 'UserDetail',
  components: {DetailLayout},
  setup() {
    return {
      ...useUserDetail(),
    };
  },
});
